<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Vuexy
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          You're not authorized 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Please contact the administrator if you need access to this link
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Back to home
        </b-button>
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    VuexyLogo,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
